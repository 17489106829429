import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { Contextfn } from "./context/Context"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
const Home = React.lazy(() => import("./pages/Home"))
const Directors = React.lazy(() => import("./pages/Directors"))
const InternationalPatients = React.lazy(() =>
  import("./pages/InternationalPatients")
)
const CorporateInsuranceServices = React.lazy(() =>
  import("./pages/CorporateInsuranceServices")
)
const Charity = React.lazy(() => import("./pages/Charity"))
const Education = React.lazy(() => import("./pages/Education"))
const Research = React.lazy(() => import("./pages/Research"))
const Doctors = React.lazy(() => import("./pages/Doctors"))
const DoctorsProfile = React.lazy(() =>
  import("./pages/Doctors/DoctorsProfile")
)
const ComingSoon = React.lazy(() => import("./pages/ComingSoon"))
const BookAppointment = React.lazy(() => import("./pages/BookAppointment"))
const PatientCare = React.lazy(() => import("./pages/PatientCare"))
const Locations = React.lazy(() => import("./pages/Locations"))
const Homage = React.lazy(() => import("./pages/Homage"))
const OurGrowthStory = React.lazy(() => import("./pages/OurGrowthStory"))
const Testimonials = React.lazy(() => import("./pages/Testimonials"))
const PoliciesPage = React.lazy(() => import("./pages/Policies/PoliciesPage"))
const Blogs = React.lazy(() => import("./pages/Blogs"))
// import { motion } from "framer-motion";

function App() {
  usePageTitle()

  {
    /* <div
                  style={{
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1
                    className="text-1 tc-2 fw-bold"
                    style={{ textAlign: "center" }}
                  >
                    404
                  </h1>
                </div> */
  }

  return (
    <Contextfn>
      <React.Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us/directors" element={<Directors />} />
            <Route path="/about-us/homage" element={<Homage />} />
            <Route path="/about-us/growth-story" element={<OurGrowthStory />} />
            <Route path="/about-us/testimonials" element={<Testimonials />} />
            <Route
              path="/about-us/testimonials-video"
              element={<Testimonials />}
            />
            <Route
              path="/international-patients"
              element={<InternationalPatients />}
            />
            <Route
              path="/corporate-insurance-services"
              element={<CorporateInsuranceServices />}
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/charity" element={<Charity />} />
            <Route path="/education" element={<Education />} />
            <Route path="/research" element={<Research />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/doctors/:slug_name" element={<DoctorsProfile />} />

            <Route path="/patient-care/:slug_name" element={<PatientCare />} />
            <Route path="/book-appointment" element={<BookAppointment />} />
            <Route path="/privacy-policy" element={<PoliciesPage />} />
            <Route path="/terms-conditions" element={<PoliciesPage />} />
            <Route path="/refunds-cancellation" element={<PoliciesPage />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/diabetes-centre-locations/:slug_name" element={<Locations />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </React.Suspense>
    </Contextfn>
  )
}

export default App

export function Loading() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Loading...</h1>
    </div>
  )
}

const usePageTitle = () => {
  useEffect(() => {
    // Get the current pathname from the URL
    const pathname = window.location.pathname

    // Remove any leading or trailing slashes and replace hyphens with spaces
    const pageTitle = pathname.replace(/^\/|\/$/g, "").replace(/-/g, " ")

    // Capitalize each word in the pathname
    const formattedTitle = pageTitle
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")

    // Set the page title
    document.title = formattedTitle || "Home"
  }, [])

  return
}
